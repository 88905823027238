/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-duplicate-props */
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid, TextField, Typography
} from '@material-ui/core';
import {
  CustomTooltip, FormElement, SelectField, SelectItemWithLeftElement
} from 'components';
import { ItemOption } from 'components/_commons/Form/index';
import { AdornmentLoader } from 'components/forms/inputs/InputField/InputField';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { CompanyService, CountryService, LegalStatusService } from 'services';
import { i18nStore } from 'stores';
import styled from 'styled-components';
import { checkRegexMismatch, translate } from 'utils';
import { STRUCTURE_TYPE } from 'utils/constants';

const CustomGridItem = styled(Grid)`
  > div,
  fieldset {
    margin-bottom: 0 !important;
  }
`;

const LegalStatusValue = ({ data, innerProps }) => (
  <ItemOption {...innerProps} style={{ gridArea: ' 1/1/2/3' }}>
    <Typography style={{ marginLeft: '0.8rem' }}>
      {data.name ?? null}
    </Typography>
  </ItemOption>
);

const LegalStatusOption = ({ data, innerProps }) => (
  <ItemOption {...innerProps} isInsideSelect noPadding>
    <Typography style={{ marginLeft: '0.8rem' }}>
      {data.name}
    </Typography>
  </ItemOption>
);

const FormCountryIdentifier = ({
  formState, text, raw, columnSize = 6, isPublic = false,
  withCompanyFields = false, identifierFields, setIdentifierFields
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentLanguage } = i18nStore;
  const [isSearchingSiren, setIsSearchingSiren] = useState(false);
  const [isSearchingSiret, setIsSearchingSiret] = useState(false);
  const [isFetchingCountries, setIsFetchingCountries] = useState(false);
  const [countriesSelectItems, setCountriesSelectItems] = useState([]);
  const [legalStatuses, setLegalStatuses] = useState([]);
  const [isFetchingIdentifierFields, setIsFetchingIdentifierFields] = useState(false);
  const [institutionIdentifier, setInstitutionIdentifier] = useState(null);
  const [institutionIdentifierRule, setInstitutionIdentifierRule] = useState(null);
  const [companyIdentifier, setCompanyIdentifier] = useState(null);
  const [companyIdentifierRule, setCompanyIdentifierRule] = useState(null);

  const initValueIdentifierField = useCallback(identifierField => {
    let defaultIdentifier = null;
    if (formState.values.identifiers) {
      defaultIdentifier = formState.values.identifiers.find(identifier => identifier.idField === identifierField.idField);
    }
    if (!defaultIdentifier) {
      defaultIdentifier = identifierFields.find(identifier => identifier.idField === identifierField.idField);
    }
    return defaultIdentifier ? {
      ...identifierField, value: defaultIdentifier.value, idValue: defaultIdentifier.idValue, suffix: defaultIdentifier.suffix
    } : identifierField;
  }, [identifierFields, formState.values.identifiers]);

  const initValueIdentifierFields = useCallback(identifierFieldsToInit => {
    if (identifierFields || formState.values.identifiers) {
      const initializedIdentifierValues = identifierFieldsToInit.map(identifierField => initValueIdentifierField(identifierField));
      setIdentifierFields(initializedIdentifierValues);
    } else {
      setIdentifierFields(identifierFieldsToInit);
    }
  }, [identifierFields, formState.values.identifiers, initValueIdentifierField, setIdentifierFields]);

  const fetchIdentifierFields = useCallback(() => {
    if (!formState.values.country) {
      return;
    }
    setIsFetchingIdentifierFields(true);
    CountryService.getIdentifiersByCountryId(formState.values.country.value)
      .then(resp => {
        initValueIdentifierFields(resp);
        setCompanyIdentifierRule(resp.find(identifier => identifier.type === STRUCTURE_TYPE.COMPANY && identifier.primary)?.rule);
        setInstitutionIdentifierRule(resp.find(identifier => identifier.type === STRUCTURE_TYPE.INSTITUTION_MODEL && identifier.primary)?.rule);
        setIsFetchingIdentifierFields(false);
      });
  }, [formState.values.country, initValueIdentifierFields]);

  const handleIdentifierValueChange = identifierField => {
    if (identifierField.type === STRUCTURE_TYPE.INSTITUTION_MODEL && identifierField.primary) {
      setInstitutionIdentifier(identifierField.value);
    }
    if (identifierField.type === STRUCTURE_TYPE.COMPANY && identifierField.primary) {
      setCompanyIdentifier(identifierField.value);
    }
    setIdentifierFields(identifierFields.map(identifier => (identifier.idField === identifierField.idField ? identifierField : identifier)));
  };

  const handleIdentifierSuffixChange = identifierField => {
    if (identifierField.type === STRUCTURE_TYPE.INSTITUTION_MODEL && identifierField.primary) {
      setIdentifierFields(identifierFields.map(identifier => (identifier.idField === identifierField.idField ? identifierField : identifier)));
    }
  };

  useEffect(() => {
    setIsFetchingCountries(true);
    CountryService.getCountrySelectItems()
      .then(countries => setCountriesSelectItems(countries))
      .finally(() => setIsFetchingCountries(false));
  }, [setCountriesSelectItems, setIsFetchingCountries]);

  const fetchLegalStatusList = useCallback(() => {
    LegalStatusService.getLegalStatusByCountryId(formState.values.country.value)
      .then(resp => setLegalStatuses(resp));
  }, [setLegalStatuses, formState.values.country]);

  const handleChangeSelectedCountry = useCallback(countryItem => {
    formState.setField('country', countryItem);
    formState.setField('legalStatus', null);
    formState.touched.legalStatus = false;
  }, [formState]);

  useEffect(() => {
    if (formState.values.country) {
      fetchLegalStatusList();
      fetchIdentifierFields();
      formState.touched.legalStatus = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values.country]);

  const resetInstitutionFields = useCallback(() => {
    formState.clearField('institutionName');
    formState.clearField('address1');
    formState.clearField('postalCode');
    formState.clearField('city');
    formState.clearField('address2');
  }, [formState]);

  const getCompanyBySiren = useCallback(() => {
    setIsSearchingSiren(true);
    CompanyService.getCompanyBySiren(companyIdentifier).then(response => {
      if (response.header.statut === 200) {
        const tempCompany = response.uniteLegale.periodesUniteLegale[0].denominationUniteLegale;
        const tempLegalStatus = legalStatuses
          .find(stat => stat.name === response.uniteLegale.periodesUniteLegale[0].categorieJuridiqueUniteLegale);
        formState.setField('companyName', tempCompany);
        if (!formState.values.legalStatus) {
          tempLegalStatus && formState.setField('legalStatus', tempLegalStatus);
        }
      } else {
        enqueueSnackbar(response.header.message, { variant: 'warning', autoHideDuration: 5000 });
      }
    })
      .catch(() => enqueueSnackbar(translate('errors.noMatchingSiren'), { variant: 'warning' }))
      .finally(() => setIsSearchingSiren(false));
    // eslint-disable-next-line
  }, [companyIdentifier, formState.values.country, enqueueSnackbar]);

  const getInstitutionBySiret = useCallback(() => {
    setIsSearchingSiret(true);
    CompanyService.getInstitutionBySiret(institutionIdentifier).then(response => {
      if (response.header && response.header.statut === 200) {
        resetInstitutionFields();
        const tempInstitution = response.etablissement.uniteLegale.denominationUniteLegale;
        const tempAddress = response.etablissement.adresseEtablissement.codePostalEtablissement;
        formState.setField('institutionName', tempInstitution);
        if (tempAddress) {
          formState.setField('address1', `${response.etablissement.adresseEtablissement.numeroVoieEtablissement || ''} ${response.etablissement.adresseEtablissement.typeVoieEtablissement || ''} ${response.etablissement.adresseEtablissement.libelleVoieEtablissement || ''}`);
          formState.setField('address2', response.etablissement.adresseEtablissement.complementAdresseEtablissement || '');
          formState.setField('postalCode', tempAddress);
          formState.setField('city', response.etablissement.adresseEtablissement.libelleCommuneEtablissement || '');
        }
      } else {
        enqueueSnackbar(response.header.message, { variant: 'warning', autoHideDuration: 5000 });
      }
    })
      .catch(() => enqueueSnackbar(translate('errors.noMatchingSiret'), { variant: 'warning' }))
      .finally(() => setIsSearchingSiret(false));
  }, [institutionIdentifier, formState, resetInstitutionFields, enqueueSnackbar]);

  useEffect(() => {
    // Launch siret search only if the siret is complete
    // && the country is France
    if (institutionIdentifier
      && institutionIdentifier.match(`^${institutionIdentifierRule?.format}$`)
      && (
        formState.values.country
        && formState.values.country.countryCode === 'fr'
      )
    ) {
      getInstitutionBySiret();
    }
    // eslint-disable-next-line
  }, [institutionIdentifier]);

  useEffect(() => {
    if (companyIdentifier
      && companyIdentifier.match(`^${companyIdentifierRule?.format}$`)
      && (
        formState.values.country
        && formState.values.country.countryCode === 'fr'
      )
    ) {
      getCompanyBySiren();
    }
    // eslint-disable-next-line
  }, [companyIdentifier]);

  const customFilter = useCallback((legalStatus, search) => {
    if (search) {
      return legalStatus.data.name.toUpperCase().includes(search.toUpperCase());
    }
    return true;
  }, []);

  return (
    <>
      <FormElement label={translate('common.country')}>
        <SelectItemWithLeftElement
          isFlagElement
          isLoading={isFetchingCountries}
          name="countries"
          options={countriesSelectItems}
          required
          value={formState.values.country}
          onChange={handleChangeSelectedCountry}
        />
      </FormElement>

      {withCompanyFields && (
        <>
          <FormElement label={translate('common.company')}>
            <Grid alignItems="center" container spacing={2}>
              {isFetchingIdentifierFields ? (
                <Grid item>
                  <FontAwesomeIcon icon={faSpinner} spin />
                  {translate('common.loading')}
                </Grid>
              )
                : (identifierFields.filter(identifier => identifier.type === STRUCTURE_TYPE.COMPANY)
                  .map(identifierField => (
                    <Grid
                      item
                      key={identifierField.idField}
                      sm={6}
                      xs={12}
                    >
                      <TextField
                        error={
                          (identifierField.required && formState.touched?.identifiers && !identifierField.value)
                          || (identifierField?.value && checkRegexMismatch(identifierField?.rule?.format, identifierField?.value))
                        }
                        helperText={(identifierField.rule?.format && identifierField.rule?.translations?.find(val => val.code === currentLanguage)?.label) ?? ''}
                        inputProps={identifierField.rule?.format ? { pattern: identifierField.rule.format } : {}}
                        label={identifierField.name}
                        name={identifierField.name}
                        required={identifierField.required}
                        value={identifierField.value ?? ''}
                        onChange={value => handleIdentifierValueChange({ ...identifierField, value: value.target.value })}
                      />
                    </Grid>
                  )))}
            </Grid>
            <Grid alignItems="center" container spacing={2}>
              {legalStatuses?.length > 0
                && <CustomGridItem item sm={6} xs={12}>
                  <SelectField
                    className={(!formState.values.legalStatus && formState.touched.legalStatus) ? 'react-select-error' : ''}
                    components={{ Option: LegalStatusOption, SingleValue: LegalStatusValue }}
                    filterOption={customFilter}
                    id="legalStatus"
                    label="common.legalStatus"
                    legendClassName={(!formState.values.legalStatus && formState.touched.legalStatus) ? 'react-select-error' : ''}
                    name="legalStatus"
                    options={legalStatuses}
                    required
                    {...raw('legalStatus')}
                  />
                </CustomGridItem>
              }
              <Grid item sm={6} xs={12}>
                <Grid container>
                  <TextField
                    autoComplete="new-password"
                    error={(formState.touched?.companyName && !formState.values.companyName) || formState.errors.companyName}
                    InputProps={{
                      endAdornment: (isSearchingSiren && <AdornmentLoader />)
                    }}
                    label={translate('common.companyReason')}
                    name="companyName"
                    required
                    {...text('companyName')}
                  />
                  <CustomTooltip text={translate('common.companyMain')} />
                </Grid>
              </Grid>
            </Grid>
            {/* This input is only used for forcing disabling autocomplete in chrome browser */}
            <input name="fakeInputForForcingDisablingAutocompleteChrome5" style={{ display: 'none' }} type="text" />
          </FormElement>
        </>
      )}

      <FormElement label={translate('common.institution')}>
        <Grid container spacing={1}>
          {isFetchingIdentifierFields ? (
            <Grid item>
              <FontAwesomeIcon icon={faSpinner} spin />
              {translate('common.loading')}
            </Grid>
          )
            : (identifierFields.filter(identifier => identifier.type === STRUCTURE_TYPE.INSTITUTION_MODEL)
              .map(identifierField => (
                <>
                  <Grid
                    item
                    key={identifierField.idField}
                    sm={columnSize}
                    xs={12}
                  >
                    <TextField
                      error={
                        (identifierField.required && formState.touched?.identifiers && !identifierField.value)
                        || (identifierField?.value && checkRegexMismatch(identifierField?.rule?.format, identifierField?.value))
                      }
                      helperText={(identifierField.rule?.format && identifierField.rule?.translations?.find(val => val.code === currentLanguage)?.label) ?? ''}
                      inputProps={identifierField.rule?.format ? { pattern: identifierField.rule?.format } : {}}
                      label={identifierField.name}
                      name={identifierField.name}
                      required={identifierField.required}
                      value={identifierField.value ?? ''}
                      onChange={value => handleIdentifierValueChange({ ...identifierField, value: value.target.value })}
                    />
                  </Grid>
                  {(isPublic || formState.values?.legalStatus?.isPublic) && identifierField.primary && (
                    <Grid item sm={columnSize} xs={12}>
                      <Grid container>
                        <TextField
                          error={formState.touched?.identifierSuffix && !identifierField.suffix}
                          label={translate('common.identifierSuffix')}
                          name="identifierSuffix"
                          required
                          value={identifierField.suffix ?? ''}
                          onChange={value => handleIdentifierSuffixChange({ ...identifierField, suffix: value.target.value })}
                        />
                        <CustomTooltip text={translate('common.identifierSuffixTooltip')} />
                      </Grid>
                    </Grid>
                  )}
                </>
              )))}
          <Grid item sm={columnSize} xs={12}>
            <Grid container>
              <TextField
                autoComplete="new-password"
                error={(formState.touched?.institutionName && !formState.values.institutionName) || formState.errors.institutionName}
                InputProps={{
                  endAdornment: (isSearchingSiret && <AdornmentLoader />)
                }}
                label={translate('common.institutionName')}
                name="institutionName"
                required
                {...text('institutionName')}
              />
              {
                withCompanyFields && <CustomTooltip text={translate('common.companyHead')} />
              }
            </Grid>
          </Grid>
        </Grid>
        {/* This input is only used for forcing disabling autocomplete in chrome browser */}
        <input name="fakeInputForForcingDisablingAutocompleteChrome3" style={{ display: 'none' }} type="text" />
      </FormElement>
    </>
  );
};

export default FormCountryIdentifier;
