/* eslint-disable camelcase */
import { faSpinner, faStoreAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DialogContent, Grid, TextField, Typography
} from '@material-ui/core';
import { FormElement, SelectField, SelectItemWithLeftElement } from 'components';
import { ItemOption } from 'components/_commons/Form';
import { AdornmentLoader } from 'components/forms/inputs/InputField/InputField';
import { IdentifierView } from 'models/IdentifierView';
import { LegalStatusView } from 'models/LegalStatusView';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormState } from 'react-use-form-state';
import { CompanyService, CountryService, LegalStatusService } from 'services';
import { i18nStore } from 'stores';
import { checkRegexMismatch, translate } from 'utils';
import { STRUCTURE_TYPE } from 'utils/constants';
import { CustomActionButtons } from './_CustomActionButtons';
import { HeaderModal } from './_HeaderModal';

const LegalStatusValue = ({ data, innerProps }) => (
  <ItemOption {...innerProps} style={{ gridArea: ' 1/1/2/3' }}>
    <Typography style={{ marginLeft: '0.8rem' }}>
      {data.name ?? null}
    </Typography>
  </ItemOption>
);

const LegalStatusOption = ({ data, innerProps }) => (
  <ItemOption {...innerProps} isInsideSelect noPadding>
    <Typography style={{ marginLeft: '0.8rem' }}>
      {data.name}
    </Typography>
  </ItemOption>
);

export const CreateCompanyModal = ({
  onConfirm, onClose, closeOnSubmit, defaultValues, withDatabilanAcc = true
}) => {
  const { currentLanguage } = i18nStore;
  const { enqueueSnackbar } = useSnackbar();
  const [isSearchingSiren, setIsSearchingSiren] = useState(false);
  const [formState, { text, raw, number }] = useFormState({
    ...defaultValues,
    country: defaultValues.country ?? { value: 0, countryCode: 'fr', label: 'France' },
    legalStatus: defaultValues.legalStatus ? defaultValues.legalStatus : null
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingCountries, setIsFetchingCountries] = useState(false);
  const [countriesSelectItems, setCountriesSelectItems] = useState([]);
  // The current values for the identifier fields
  const [identifierFields, setIdentifierFields] = useState<IdentifierView[]>([]);
  // The available identifier fields for the selected country
  const [availableFields, setAvailableFields] = useState<IdentifierView[]>([]);
  const [legalStatuses, setLegalStatuses] = useState<LegalStatusView[]>([]);
  const [touchedFields, setTouchedFields] = useState({});

  const setTouched = useCallback((field) => {
    setTouchedFields(prevState => ({
      ...prevState,
      [field]: true
    }));
  }, []);

  const handleBlur = (field: string) => {
    setTouched(field);
  };

  const updateIdentifiers = useCallback((newFields: IdentifierView[]) => {
    // Replace the existing fields with the new ones
    setIdentifierFields((value) => availableFields.map(field => {
      const newField = newFields?.find(f => f.idField === field.idField);
      if (newField) {
        const { idField } = newField;
        setTouchedFields({
          ...touchedFields,
          [idField]: false
        });
        return newField;
      }
      const existingField = value?.find(f => f.idField === field.idField);
      if (existingField) {
        return existingField;
      }
      return field;
    }));
  }, [availableFields, touchedFields]);

  const generateTVAFromSirenIfEmpty = useCallback((sirenField) => {
    let tvaIntraField = availableFields?.find((idField) => idField.name === 'TVA intracommunautaire');
    if (tvaIntraField) {
      let tvaCode = `${(12 + 3 * (Number(sirenField.value) % 97)) % 97}`;
      if (tvaCode.length === 1) {
        tvaCode = `0${tvaCode}`;
      }
      const tvaIntraValue = `FR ${tvaCode}${sirenField.value}`;
      tvaIntraField = {
        ...tvaIntraField,
        value: tvaIntraValue
      };
      updateIdentifiers([sirenField, tvaIntraField]);
    } else {
      updateIdentifiers([sirenField]);
    }
  }, [availableFields, updateIdentifiers]);

  const getCompanyBySiren = useCallback((sirenField: IdentifierView) => {
    generateTVAFromSirenIfEmpty(sirenField);
    setIsSearchingSiren(true);
    CompanyService.getCompanyBySiren(sirenField.value).then(response => {
      if (response.header.statut === 200) {
        const tempCompany = response.uniteLegale.periodesUniteLegale[0].denominationUniteLegale;
        const tempLegalStatus = legalStatuses?.find(stat => stat.name === response.uniteLegale.periodesUniteLegale[0].categorieJuridiqueUniteLegale);
        if (!formState.values.name) {
          formState.setField('name', tempCompany);
        }
        if (!formState.values.legalStatus) {
          tempLegalStatus && formState.setField('legalStatus', tempLegalStatus);
        }
      } else {
        enqueueSnackbar(response.header.message, { variant: 'warning', autoHideDuration: 5000 });
      }
    }).catch(() => {
      enqueueSnackbar(translate('errors.noMatchingSiren'), { variant: 'warning', autoHideDuration: 5000 });
    }).finally(() => setIsSearchingSiren(false));
      // eslint-disable-next-line
  }, [enqueueSnackbar, formState?.values, availableFields, legalStatuses, updateIdentifiers, identifierFields, generateTVAFromSirenIfEmpty]);

  const handleIdentifierValueChange = useCallback((identifierField: IdentifierView, cascade: boolean = false) => {
    if (identifierField.primary && !checkRegexMismatch(identifierField.rule?.format, identifierField.value)
      && formState.values.country
      && formState.values.country.countryCode === 'fr'
      && cascade
    ) {
      getCompanyBySiren(identifierField);
    } else {
      updateIdentifiers([identifierField]);
    }
  }, [formState.values.country, getCompanyBySiren, updateIdentifiers]);

  useEffect(() => {
    // When the available identifier fields change, initialise them with the current data
    availableFields.forEach(idFieldToInit => {
      const existingField = formState.values.identifiers?.find(idField => idField.idField === idFieldToInit.idField);
      // If the field is already defined with a value, reload it to update other fields
      if (existingField && existingField.value) {
        handleIdentifierValueChange(existingField);
      } else {
        handleIdentifierValueChange(idFieldToInit);
      }
    });
    // eslint-disable-next-line
  }, [availableFields]);

  const fetchLegalStatusList = useCallback(() => {
    LegalStatusService.getLegalStatusByCountryId(formState.values.country.value)
      // eslint-disable-next-line no-nested-ternary
      .then(resp => setLegalStatuses(resp.sort((a, b) => a.name?.localeCompare(b.name))));
  }, [setLegalStatuses, formState.values.country.value]);

  const handleChangeSelectedCountry = useCallback(countryItem => {
    formState.setField('country', countryItem);
    CountryService.getCountryById(countryItem.id)
      .then(() => setIdentifierFields([]))
      .then(() => formState.setField('legalStatus', null));
  }, [formState]);

  useEffect(() => {
    setIsFetchingCountries(true);
    CountryService.getCountrySelectItems()
      .then(json => {
        setCountriesSelectItems(json);
      })
      .finally(() => setIsFetchingCountries(false));
  }, [setCountriesSelectItems, setIsFetchingCountries]);

  useEffect(() => {
    setIsLoading(true);
    CountryService.getCountryById(formState.values.country.value)
      .then((country) => {
        fetchLegalStatusList();
        const companyIdentifierFields = country.identifiers.filter(identifierField => identifierField.type === STRUCTURE_TYPE.COMPANY);
        setAvailableFields(companyIdentifierFields);
      }).finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      });
    // eslint-disable-next-line
  }, [defaultValues, formState.values.country]);

  const handleValidateModal = useCallback(e => {
    e.preventDefault();
    const { forms } = document as any;
    const isFormValid = forms.companyForm && forms.companyForm.reportValidity();
    if (isFormValid) {
      onConfirm({
        ...formState.values,
        identifiers: identifierFields,
        id: defaultValues.id || null
      });

      if (closeOnSubmit) {
        onClose();
      }
    }
  }, [onConfirm, formState.values, identifierFields, defaultValues.id, closeOnSubmit, onClose]);

  const handleChangeAccountLimit = useCallback(e => {
    const { target: { value } } = e;
    const limit = Number(value);

    if (limit < 1) {
      e.preventDefault();
      return;
    }

    formState.setField('databilanAccountLimit', limit);
  }, [formState]);

  const customFilter = useCallback((legalStatus, search) => {
    if (search) {
      return (legalStatus.data.name).toUpperCase().includes(search.toUpperCase());
    }
    return true;
  }, []);

  return (
    <form autoComplete="off" name="companyForm" onSubmit={handleValidateModal}>
      <HeaderModal onClose={onClose}>
        <FontAwesomeIcon icon={faStoreAlt} />
        <Typography component="span">
          {defaultValues && defaultValues.name
            ? translate('modalCompany.editCompany')
            : translate('modalCompany.newCompany')}
        </Typography>
      </HeaderModal>

      <DialogContent style={{ width: '500px' }}>
        <Grid container direction="column">
          <FormElement label={translate('common.country')}>
            <SelectItemWithLeftElement
              isFlagElement
              isLoading={isFetchingCountries}
              label="common.selectCountry"
              name="countries"
              options={countriesSelectItems}
              onChange={handleChangeSelectedCountry}
              {...raw('country')}
            />
          </FormElement>
          <FormElement label={translate('common.company')}>
            {isLoading ? (
              <Grid item>
                <FontAwesomeIcon icon={faSpinner} spin />
                {translate('common.loading')}
              </Grid>
            )
              : (identifierFields.map(identifierField => {
                const {
                  idField, value, name, required, rule
                } = identifierField;
                return (
                  <TextField
                    error={(touchedFields[idField] && !value) || (value && checkRegexMismatch(rule?.format, value))}
                    helperText={(rule?.format && rule?.translations?.find(val => val.code === currentLanguage)?.label) ?? ''}
                    inputProps={rule?.format ? { pattern: rule.format } : {}}
                    key={idField}
                    label={name}
                    name={name}
                    required={required}
                    value={value ?? ''}
                    onBlur={() => handleBlur(idField)}
                    onChange={val => handleIdentifierValueChange({ ...identifierField, value: val.target.value }, true)}
                  />
                );
              }))}
            <TextField
              error={!!formState.errors.name}
              InputProps={{
                endAdornment: (isSearchingSiren && <AdornmentLoader />)
              }}
              label={translate('common.corporateName')}
              name="name"
              required
              {...text('name')}
            />
            {withDatabilanAcc && (
              <TextField
                error={!!formState.errors.databilanAccountLimit}
                inputProps={{ min: 0 }}
                label={translate('common.databilanAccountLimit')}
                name="databilanAccountLimit"
                {...number({
                  name: 'databilanAccountLimit',
                  onChange: handleChangeAccountLimit
                })}
              />
            )}
          </FormElement>

          <div style={{ margin: '1rem 0' }} />

          <SelectField
            components={{ Option: LegalStatusOption, SingleValue: LegalStatusValue }}
            filterOption={customFilter}
            label="common.legalStatus"
            menuPlacement="top"
            name="legalStatus"
            options={legalStatuses ?? []}
            required
            {...raw('legalStatus')}
          />
        </Grid>
      </DialogContent>

      <CustomActionButtons
        isValidated={defaultValues.validated}
        onClose={onClose}
      />
    </form>
  );
};

CreateCompanyModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  closeOnSubmit: PropTypes.bool,
  defaultValues: PropTypes.shape({})
};

CreateCompanyModal.defaultProps = {
  closeOnSubmit: true,
  defaultValues: {}
};
